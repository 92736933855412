define("shared/helpers/t-stage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    onLocaleChange: Ember.observer('intl.primaryLocale', function () {
      this.recompute();
    }),
    // Pass getDefault=true if you want to get the original definition in the intl file
    // and not any overrides that might be present in the project's translation dictionary.
    compute: function compute(_ref, _ref2) {
      var _ref3 = _slicedToArray(_ref, 2),
          project = _ref3[0],
          key = _ref3[1];

      var getDefault = _ref2.getDefault,
          locale = _ref2.locale;
      // Eventhough we don't use this on the first getDefault check
      // it has to be accessed once for all of them in order for
      // the recompute observer to activate.
      var intl = this.get('intl');

      if (!getDefault) {
        var property = project.get("t.".concat(key));

        if (Ember.isPresent(property)) {
          return property;
        }
      }

      if (locale) {
        return intl.lookup("purpose.".concat(project.get('purpose'), ".stage.").concat(key), locale);
      } else {
        return intl.lookup("purpose.".concat(project.get('purpose'), ".stage.").concat(key));
      }
    }
  });

  _exports.default = _default;
});