define("authentication/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cookie.default.extend({
    init: function init() {
      var data = {};
      window.location.search.substring(1).split('&').forEach(function (item) {
        var parts = item.split('=');
        data[parts[0]] = parts[1];
      });

      if (Ember.isBlank(data.order_id)) {
        var parts = window.location.pathname.split('/');

        if (parts.length > 4) {
          var order_id = parts[4];

          if (!isNaN(order_id)) {
            data.order_id = order_id;
          }
        }
      }

      if (Ember.isPresent(data.order_id)) {
        this._cookieName = "_crowdox-session-order-".concat(data.order_id);
      } else {
        this._cookieName = '_crowdox-session';
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});