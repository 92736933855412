define("shared/utils/fallback-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.fallbackSort = fallbackSort;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function fallbackSort() {
    var sort_orders = Array.prototype.slice.call(arguments);
    return function (a, b) {
      // First see if we can sort_order by the sort_order field
      if (Ember.get(a, 'sort_order') != null && Ember.get(b, 'sort_order') != null) {
        // if we have an sort_order, sort_order asc
        if (Ember.get(a, 'sort_order') > Ember.get(b, 'sort_order')) {
          return 1;
        }

        if (Ember.get(a, 'sort_order') < Ember.get(b, 'sort_order')) {
          return -1;
        }
      } // if we have an sort_order and dont, sort_order above


      if (Ember.get(a, 'sort_order') == null && Ember.get(b, 'sort_order') != null) {
        return 1;
      }

      if (Ember.get(a, 'sort_order') != null && Ember.get(b, 'sort_order') == null) {
        return -1;
      } // Fallback to other columns


      for (var i = 0, field; i < sort_orders.length; i++) {
        field = sort_orders[i];

        if (Ember.get(a, field) > Ember.get(b, field)) {
          return 1;
        }

        if (Ember.get(a, field) < Ember.get(b, field)) {
          return -1;
        }
      }

      return 0;
    };
  }

  function _default() {
    var computedProperties = Array.prototype.slice.call(arguments);
    var collectionName = computedProperties.shift();
    computedProperties.unshift('sort_order');
    var computedPropertyName = '';

    if (computedProperties.length === 1) {
      computedPropertyName = '.@each.' + computedProperties.join(',');
    } else {
      computedPropertyName = '.@each.{' + computedProperties.join(',') + '}';
    }

    return Ember.computed.sort(collectionName + computedPropertyName, fallbackSort.apply(void 0, _toConsumableArray(computedProperties)));
  }
});