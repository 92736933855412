define("shared/components/order-payment-totals/component", ["exports", "shared/components/order-payment-totals/template", "shared/utils/computed-coalesce"], function (_exports, _template, _computedCoalesce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: "",
    payment_expanded: false,
    can_expand: true,
    calculated_paid: (0, _computedCoalesce.default)('calculated.paid', 'order.paid'),
    direct_payments: Ember.computed.readOnly('order.direct_payments'),
    manual_payments: Ember.computed.readOnly('order.manual_payments'),
    external_payments: Ember.computed.readOnly('order.external_payments'),
    show: Ember.computed('calculated_paid', 'direct_payments', 'manual_payments', 'external_payments', function () {
      if (this.get('calculated_paid') !== 0) {
        return true;
      }

      var items = [this.get('direct_payments'), this.get('manual_payments'), this.get('external_payments')];

      for (var _i = 0, _items = items; _i < _items.length; _i++) {
        var item = _items[_i];

        if (Ember.isPresent(item) && item !== 0) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      toggle_payment: function toggle_payment() {
        if (this.get('can_expand') === false) {
          return;
        }

        this.toggleProperty('payment_expanded');
      }
    }
  });

  _exports.default = _default;
});