define("shared/services/configure-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    added: function added() {
      this.trigger('added');
    }
  });

  _exports.default = _default;
});