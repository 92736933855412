define("shared/tests/helpers/model-helpers", ["exports", "ember-data", "ember-inflector", "shared/utils/logger", "@ember-data/model", "@ember-data/adapter/json-api"], function (_exports, _emberData, _emberInflector, _logger, _model, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.load_store = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Hack to get dynamic access to exports
  var exports = null;
  eval('exports = _exports');

  var next_id = function next_id(store, modelName) {
    var ids = store.peekAll(modelName).mapBy('id');
    ids.push(0);
    return Math.max.apply(Math, _toConsumableArray(ids)) + 1;
  };

  var build_record = function build_record(store, modelName) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var merge_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    data = Ember.assign(data, merge_data);
    return store.createRecord(modelName, data);
  };

  var create_record = function create_record(store, modelName) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var merge_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var id = next_id(store, modelName);
    data['id'] = id;
    data = Ember.assign(data, merge_data);
    var json_data = {
      id: null,
      type: modelName,
      attributes: {},
      relationships: {}
    };

    for (var _i = 0, _Object$keys = Object.keys(data); _i < _Object$keys.length; _i++) {
      var key = _Object$keys[_i];

      if (key === "id") {
        json_data.id = data[key];
        continue;
      }

      var value = data[key];

      if (value == null) {
        continue;
      }

      if (Ember.PromiseProxyMixin.detect(value)) {
        value = value.get('content');
      }

      var dasherized_key = Ember.String.dasherize(key);

      if (value instanceof _model.default) {
        if (value.get('_isFragment')) {
          json_data.attributes[dasherized_key] = value.toJSON();
        } else {
          json_data.relationships[dasherized_key] = {
            data: {
              type: (0, _emberInflector.pluralize)(value.get('_internalModel.modelName')),
              id: value.get('id')
            }
          };
        }

        continue;
      }

      if (value instanceof Date) {
        json_data.attributes[dasherized_key] = value.toISOString();
        continue;
      }

      if (["string", "boolean", "number"].includes(_typeof(value)) === false) {
        (0, _logger.logger_warn)("Unexpected type in create_record of:".concat(_typeof(value)));
      }

      json_data.attributes[dasherized_key] = value;
    }

    store.pushPayload(modelName, {
      data: json_data
    });
    return store.peekRecord(modelName, json_data.id);
  };

  var load_store = function load_store() {
    var container = this.owner || this.container;
    var adapterFactory = container.factoryFor('adapter:application');

    if (!adapterFactory) {
      if (window.requirejs.entries['ember-data/adapters/json-api']) {
        adapterFactory = window.require('ember-data/adapters/json-api')['default'];
      } // when ember-data/adapters/json-api is provided via ember-cli shims
      // using Ember Data 1.x the actual JSONAPIAdapter isn't found, but the
      // above require statement returns a bizzaro object with only a `default`
      // property (circular reference actually)


      if (!adapterFactory || !adapterFactory.create) {
        adapterFactory = _jsonApi.default || _emberData.default.FixtureAdapter;
      }

      var registry = this.owner || this.registry;
      registry.register('adapter:application', adapterFactory);
    }

    this.store = container.lookup('service:store') || container.lookup('store:main');
  };

  _exports.load_store = load_store;
  var operations = ['build', 'create'];

  var _loop = function _loop() {
    var operation = _operations[_i2];
    var perform_record = operation === 'build' ? build_record : create_record;

    exports[operation + '_project'] = function (store) {
      var extra_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return perform_record(store, 'project', {
        unit_of_mass: 'kg',
        shipping_type: 'product_price',
        currency: 'USD',
        purpose: 'pledge_management'
      }, extra_data);
    };

    exports[operation + '_project_translation'] = function (store, project) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'project-translation', {
        project: project,
        locale: 'en'
      }, extra_data);
    };

    exports[operation + '_project_source'] = function (store, project, site) {
      var extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'project-source', {
        project: project,
        site: site,
        status: 'live'
      }, extra_data);
    };

    exports[operation + '_project_configuration'] = function (store, project, source, product_reward, product_extra) {
      var extra_data = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
      return perform_record(store, 'project-configuration', {
        project: project,
        source: source,
        product_reward: product_reward,
        product_extra: product_extra
      }, extra_data);
    };

    exports[operation + '_project_address'] = function (store, project) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'project-address', {
        project: project
      }, extra_data);
    };

    exports[operation + '_project_zone'] = function (store, project, location_type, cost_type) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'project-zone', {
        project: project,
        cost_type: cost_type,
        location_type: location_type
      }, extra_data);
    };

    exports[operation + '_project_zone_location'] = function (store, project, zone, cost_type) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'project-zone-location', {
        project: project,
        zone: zone,
        cost_type: cost_type
      }, extra_data);
    };

    exports[operation + '_customer'] = function (store, project) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'customer', {
        project: project
      }, extra_data);
    };

    exports[operation + '_order'] = function (store, project, customer, source) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'order', {
        project: project,
        customer: customer,
        source: source
      }, extra_data);
    };

    exports[operation + '_order_address'] = function (store, order) {
      var set_default = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var address = perform_record(store, 'order-address', {
        project: order.get('project'),
        order: order
      }, extra_data);

      if (set_default) {
        order.set('shipping_address', address);
      }

      return address;
    };

    exports[operation + '_order_transaction'] = function (store, order) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'order-transaction', {
        project: order.get('project'),
        currency: order.get('project.currency'),
        order: order
      }, extra_data);
    };

    exports[operation + '_order_line'] = function (store, order) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'order-line', {
        project: order.get('project'),
        order: order,
        line_type: 'reward',
        sort_order: 1
      }, extra_data);
    };

    exports[operation + '_order_selection'] = function (store, order, order_line) {
      var extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'order-selection', {
        project: order.get('project'),
        order: order,
        line: order_line,
        quantity: 1
      }, extra_data);
    };

    exports[operation + '_order_selection_question'] = function (store, order, order_selection) {
      var extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'order-selection-question', {
        project: order.get('project'),
        order: order,
        selection: order_selection
      }, extra_data);
    };

    exports[operation + '_product'] = function (store, project, product_type) {
      var extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var skip_variations = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var product = perform_record(store, 'product', {
        project: project,
        product_type: product_type
      }, extra_data);

      if (['physical', 'digital', 'shipping'].includes(product_type) && skip_variations === false) {
        exports[operation + '_product_variation'](store, product, {
          is_default: true,
          has_variants: false
        });
      }

      return product;
    };

    exports[operation + '_product_question'] = function (store, product, extra_data) {
      return perform_record(store, 'product-question', {
        project: product.get('project'),
        product: product,
        required: false,
        sort_order: 1,
        text: 'A Question',
        question_type: 'text',
        sub_type: 'any'
      }, extra_data);
    };

    exports[operation + '_product_variant'] = function (store, product) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'product-variant', {
        project: product.get('project'),
        product: product
      }, extra_data);
    };

    exports[operation + '_product_variant_value'] = function (store, product, variant) {
      var extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'product-variant-value', {
        project: product.get('project'),
        product: product,
        variant: variant
      }, extra_data);
    };

    exports[operation + '_product_variation'] = function (store, product) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var variant_value_combinations = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      var variation = perform_record(store, 'product-variation', {
        project: product.get('project'),
        product: product,
        enabled: true,
        is_default: false,
        has_variants: true
      }, extra_data);

      if (variant_value_combinations != null && variant_value_combinations.length > 0) {
        var details_data = store.createFragment('product-details');
        variation.set('details_data', details_data);

        var _iterator = _createForOfIteratorHelper(variant_value_combinations),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var variant_value = _step.value;
            var variant = variant_value.get('variant.content') || variant_value.get('variant');
            details_data.get('details').createFragment({
              _variant: variant,
              variant_id: variant.get('id'),
              _variant_value: variant_value,
              variant_value_id: variant_value.get('id')
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      return variation;
    };

    exports[operation + '_product_mapping'] = function (store, project, parent, child) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'product-mapping', {
        project: project,
        parent: parent,
        child: child,
        enabled: true,
        is_default: false,
        required_quantity: 1,
        sort_order: 1
      }, extra_data);
    };

    exports[operation + '_product_mapping_with_bundle'] = function (store, project) {
      var extra_product_mapping_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var parent_product = exports[operation + '_product'](store, project, 'bundle');
      var child_product = exports[operation + '_product'](store, project, 'physical');
      return exports[operation + '_product_mapping'](store, project, parent_product, child_product, extra_product_mapping_data);
    };

    exports[operation + '_product_zone'] = function (store, product, location_type, cost_type) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'product-zone', {
        project: product.get('project'),
        product: product,
        cost_type: cost_type,
        location_type: location_type
      }, extra_data);
    };

    exports[operation + '_product_zone_location'] = function (store, product, zone, cost_type) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'product-zone-location', {
        project: product.get('project'),
        product: product,
        zone: zone,
        cost_type: cost_type
      }, extra_data);
    };

    exports[operation + '_country'] = function (store) {
      var extra_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return perform_record(store, 'country', extra_data);
    };

    exports[operation + '_state'] = function (store, country) {
      var extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'state', {
        country: country
      }, extra_data);
    };

    exports[operation + '_vat_category'] = function (store, project, name, public_id) {
      var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'vat-category', {
        project: project,
        name: name,
        public_id: public_id
      }, extra_data);
    };
  };

  for (var _i2 = 0, _operations = operations; _i2 < _operations.length; _i2++) {
    _loop();
  } // Special helpers


  exports.create_order_with_project = function (store) {
    var site = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'kickstarter';
    var extra_order_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var project = exports.create_project(store);
    var customer = exports.create_customer(store, project);
    var source = exports.create_project_source(store, project, site);
    var order = exports.create_order(store, project, customer, source, extra_order_data);
    return order;
  };

  exports.build_order_with_project = function (store) {
    var site = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'kickstarter';
    var extra_order_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var project = exports.create_project(store);
    var customer = exports.create_customer(store, project);
    var source = exports.create_project_source(store, project, site);
    var order = exports.build_order(store, project, customer, source, extra_order_data);
    return order;
  };
});