define("shared/mixins/route-css", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var routePaths = Ember.A();

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      var root = this.root();
      var cssClass = this.toCssClass();

      if (cssClass.name !== 'application') {
        var existing = routePaths.findBy('key', cssClass.key);

        if (!existing) {
          routePaths.addObject(cssClass);
        }

        root.addClass(this.generateRoutePath());
      }

      root.removeClass('loading-full');
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var root = this.root();
      var cssClass = this.toCssClass();

      if (cssClass.name !== 'application') {
        root.removeClass(this.generateRoutePath());
        routePaths = routePaths.rejectBy('key', cssClass.key);
      }
    },
    root: function root() {
      var element = (0, _jquery.default)('html');

      if (!element.hasClass('root')) {
        element.addClass('root');
      }

      return element;
    },
    toCssClass: function toCssClass() {
      var names = this.routeName.split('.');
      var length = names.length;
      var name = Ember.String.dasherize(names.slice(-1)[0]).replace('/', '-').toLowerCase();
      return {
        name: name,
        position: length,
        key: "".concat(name, ":").concat(length)
      };
    },
    generateRoutePath: function generateRoutePath() {
      return routePaths.sortBy('position').mapBy('name').join('-');
    },
    actions: {
      loading: function loading() {
        var root = this.root();
        root.addClass('loading-full');
        return true;
      },
      didTransition: function didTransition() {
        var root = this.root();
        root.removeClass('loading-full');
        return true;
      },
      error: function error() {
        var root = this.root();
        root.removeClass('loading-full');
        return true;
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      var root = this.root();
      root.removeClass('loading-full');
    }
  });

  _exports.default = _default;
});