define("shared/transforms/array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return Ember.A(serialized);
    },
    serialize: function serialize(deserialized) {
      return (deserialized || Ember.A()).toArray();
    }
  });

  _exports.default = _default;
});