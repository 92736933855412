define("shared/components/product-bundle-configuration/component", ["exports", "shared/components/product-bundle-configuration/template", "shared/utils/product-mapping-order-selection-filter"], function (_exports, _template, _productMappingOrderSelectionFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    // classNames: ['product-bundle-configuration', 'component'],
    tagName: "",
    project: Ember.computed.alias('order_line.project'),
    init: function init() {
      if (Ember.isBlank(this.get('order_line'))) {
        throw "An order_line is required";
      }

      this._super.apply(this, arguments);
    },
    actions: {
      get_order_selection: function get_order_selection(order_line, product_mapping) {
        var order_selection = order_line.get('selections_not_deleted').find((0, _productMappingOrderSelectionFilter.default)(product_mapping));

        if (Ember.isBlank(order_selection)) {
          throw "Selection for product mapping in product-configuration wasn't found.";
        }

        return order_selection;
      }
    }
  });

  _exports.default = _default;
});