define('ember-spread/mixins/spread', ['exports', 'ember-prop-types'], function (exports, _emberPropTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var assign = Object.assign,
      keys = Object.keys;


  // Constants
  var SPREAD_PROPERTY = 'options';
  // Reserved keys used by spread on source property
  var EXCLUDED_PROPERTIES = ['setUnknownProperty', '_spreadListeners'];

  exports.default = Ember.Mixin.create({

    // == Dependencies ==========================================================

    // == Properties ============================================================

    propTypes: {
      // Keywords

      // Options
      options: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.EmberObject, _emberPropTypes.PropTypes.object]),
      spreadOptions: _emberPropTypes.PropTypes.shape({
        property: _emberPropTypes.PropTypes.string,
        source: _emberPropTypes.PropTypes.shape({
          object: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.EmberObject, _emberPropTypes.PropTypes.object]),
          property: _emberPropTypes.PropTypes.string.isRequired
        })
      })

      // State
    },

    // == Functions =============================================================
    /**
     * Create local properties for each property in the spread hash.
     * Functions are set directly against the local object. Properties listed in
     * the component's `concatenatedProperties` or `mergedProperties` are
     * concatenated / merged appropriately.
     *
     * Note: These properties are not observed for changes.
     *
     * All other properties are readOnly computed properties to retain
     * observer behavior.
     *
     * Note: We're currently using the private Ember defineProperty function
     * which is required to establish observer chains (accept computed properties)
     *
     * @param {string} spreadProperty - the name of the local property containing the hash
     * @param {object} spreadableHash - the hash to spread
     * @param {string[]} staticProperties - properties that are not set up as an alias
     */
    _defineSpreadProperties: function _defineSpreadProperties(spreadProperty, spreadableHash) {
      var _this = this;

      var staticProperties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ['tagName', 'elementId'];
      (false && !(['instance', 'object'].includes(Ember.typeOf(spreadableHash))) && Ember.assert(spreadProperty + ' requires an Ember object or primitive object', ['instance', 'object'].includes(Ember.typeOf(spreadableHash))));


      var concatenatedProperties = this.concatenatedProperties;
      var mergedProperties = this.mergedProperties;

      // NOTE: disabled linting rule to stay as close as possible to Ember core's code
      // eslint-disable-next-line complexity
      keys(spreadableHash).forEach(function (key) {
        if (EXCLUDED_PROPERTIES.includes(key)) {
          return;
        }

        var value = spreadableHash[key];

        if (staticProperties.includes(key) || Ember.typeOf(value) === 'function') {
          _this.set(key, value);
          return;
        }

        // Based on
        // https://github.com/emberjs/ember.js/blob/v2.12.0/packages/ember-runtime/lib/system/core_object.js#L127-L141
        if (Array.isArray(concatenatedProperties) && concatenatedProperties.indexOf(key) !== -1) {
          var baseValue = _this[key];

          if (!baseValue) {
            _this.set(key, Ember.makeArray(value));
          } else if (typeof baseValue.concat === 'function') {
            _this.set(key, baseValue.concat(value));
          } else {
            _this.set(key, Ember.makeArray(baseValue).concat(value));
          }
          _this.notifyPropertyChange('' + key);
          return;
        }

        // Based on
        // https://github.com/emberjs/ember.js/blob/v2.12.0/packages/ember-runtime/lib/system/core_object.js#L143-L149
        if (Array.isArray(mergedProperties) && mergedProperties.indexOf(key) !== -1) {
          var originalValue = _this[key];

          if (Ember.typeOf(value) === 'object') {
            if (Ember.typeOf(originalValue) === 'object') {
              _this.set(key, assign({}, originalValue, value));
            } else {
              _this.set(key, assign({}, value));
            }
          }
          _this.notifyPropertyChange('' + key);
          return;
        }
        Ember.defineProperty(_this, key, Ember.computed.readOnly(spreadProperty + '.' + key));
        _this.notifyPropertyChange('' + key);
      });
    },


    /**
     * Reset local properties to undefined for each property in the spread hash to break the observer.
     * Properties listed in the component's `concatenatedProperties` or `mergedProperties`
     * are remain untouched.
     *
     * All other readOnly computed properties properties are being reset to undefined.
     *
     * Note: We're currently using the private Ember defineProperty function
     * which is required to establish observer chains (accept computed properties)
     *
     */
    _resetSpreadProperties: function _resetSpreadProperties() {
      var _this2 = this;

      var staticProperties = ['tagName', 'elementId'];
      var concatenatedProperties = this.concatenatedProperties || Ember.makeArray();
      var mergedProperties = this.mergedProperties || Ember.makeArray();
      var spreadProperties = this.get('_spreadProperties');

      if (Ember.isNone(spreadProperties)) {
        return;
      }

      spreadProperties.forEach(function (key) {
        // We don't reset tagName, elementId, concatenatedProperties and
        // mergedProperties as we won't support change them on the fly.
        if (staticProperties.includes(key) || concatenatedProperties.includes(key) || mergedProperties.includes(key)) {
          return;
        }

        // As user has replaced/reset the spreadable property on source object, we are
        // going to remove all registered computed properties.
        Ember.defineProperty(_this2, key, undefined, undefined);
      });
      this.set('_spreadProperties', new Set());
    },


    // == Ember Lifecycle Hooks =================================================
    init: function init() {
      this._super.apply(this, arguments);

      var _getSpreadSource = this._getSpreadSource(),
          propertyPath = _getSpreadSource.propertyPath,
          spreadSource = _getSpreadSource.spreadSource;

      if (spreadSource) {
        var spreadProperties = new Set(Object.keys(spreadSource));
        this.set('_spreadProperties', spreadProperties);
        this._addSetUnsupportedProperty(propertyPath);
        this._defineSpreadProperties(propertyPath, spreadSource);
      }

      this.addObserver(propertyPath, this, this._sourceChangeObserverHandler);
    },


    /**
     * return an object that has the source object that needs to be spread onto current
     * component and the path to the source relative to the current object.
     * @returns {{spreadSource: object, propertyPath: string}} a hash with spread source reference and its path.
     * @private
     */
    _getSpreadSource: function _getSpreadSource() {
      // Get the source of  spreadable hash, can be either
      // this.options (default) OR
      // this.${spreadOptions.property} (custom)
      // spreadOptions.source.object.${spreadOptions.source.property} (with dynamic properties)
      var propertyPath = this.get('spreadOptions.property') || SPREAD_PROPERTY;
      if (this.get('spreadOptions.source.object')) {
        var pathSuffix = this.get('spreadOptions.source.property') || SPREAD_PROPERTY;
        propertyPath = 'spreadOptions.source.object.' + pathSuffix;
      }
      return {
        propertyPath: propertyPath,
        spreadSource: this.get(propertyPath)
      };
    },


    /**
     * Adding {@code setUnknownProperty} to the spread source object if it does not have it
     * in order to be able to detect addition of properties in the spread source object.
     * @param {string} spreadPropertyPath - path to the spread source object (relative to current component)
     * @private
     */
    _addSetUnsupportedProperty: function _addSetUnsupportedProperty(spreadPropertyPath) {
      var _this3 = this;

      var spreadSource = this.get('' + spreadPropertyPath);
      spreadSource.setUnknownProperty = function (key, value) {
        spreadSource[key] = value;
        _this3._defineSpreadProperties(spreadPropertyPath, _defineProperty({}, '' + key, value));
        _this3.get('_spreadProperties').add(key);
      };
    },


    /**
     * observer to detect changes in the spread source reference so that we can update
     * spread properties
     * @private
     */
    _sourceChangeObserverHandler: function _sourceChangeObserverHandler() {
      var _getSpreadSource2 = this._getSpreadSource(),
          propertyPath = _getSpreadSource2.propertyPath,
          spreadSource = _getSpreadSource2.spreadSource;

      if (spreadSource === undefined) {
        this._resetSpreadProperties();
      } else if (spreadSource.setUnknownProperty === undefined) {
        this._resetSpreadProperties();
        this._addSetUnsupportedProperty(propertyPath);
      }
      if (spreadSource) {
        this._defineSpreadProperties(propertyPath, spreadSource);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this._resetSpreadProperties();
      this.set('_spreadProperties', undefined);

      var _getSpreadSource3 = this._getSpreadSource(),
          propertyPath = _getSpreadSource3.propertyPath;

      this.removeObserver(propertyPath, this, this._sourceChangeObserverHandler);
    }

    // == DOM Events ============================================================

    // == Actions ===============================================================

  });
});