define("shared/initializers/model-cp-validations", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _model.default.reopen({
      isCpValid: Ember.computed.readOnly('validations.isValid'),
      isCpInvalid: Ember.computed.readOnly('validations.isInvalid'),
      isCpValidating: Ember.computed.readOnly('validations.isValidating'),
      isCpTruelyValid: Ember.computed.readOnly('validations.isTruelyValid')
    });
  }

  var _default = {
    name: 'model-cp-validations',
    initialize: initialize
  };
  _exports.default = _default;
});