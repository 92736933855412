define("shared/tests/helpers/create-configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(project, source) {
    var name = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Configuration 10';
    var price_cents = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
    var rewards = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    var extras = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];
    var status = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 'setup';
    var mappings_enabled = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : true;
    var product_reward = server.create('product', {
      project: project,
      name: name,
      price_cents: price_cents,
      product_type: 'bundle'
    });
    var product;

    for (var i = 0; i < rewards.length; i++) {
      product = rewards[i];
      server.create('product-mapping', {
        enabled: mappings_enabled,
        is_default: false,
        required_quantity: 1,
        project: project,
        parent: product_reward,
        child: product
      });
    }

    var product_extra = server.create('product', {
      project: project,
      product_type: 'mapping'
    });

    if (extras) {
      for (var j = 0; j < extras.length; j++) {
        product = extras[j];
        server.create('product-mapping', {
          enabled: true,
          is_default: false,
          project: project,
          parent: product_extra,
          child: product
        });
      }
    }

    return server.create('project-configuration', {
      name: name,
      status: status,
      source: source,
      project: project,
      product_reward: product_reward,
      product_extra: product_extra
    });
  }
});