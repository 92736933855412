define("shared/initializers/ember-ajax", ["exports", "ember-ajax/services/ajax", "shared/utils/logger"], function (_exports, _ajax, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _ajax.default.reopen({
      store: Ember.inject.service(),
      session: Ember.inject.service(),
      headers: Ember.computed('session.isAuthenticated', 'session.data.authenticated.{type,email,token,order_id}', {
        get: function get() {
          var headers = {};

          var _ref = this.get('session.data.authenticated') || {},
              token = _ref.token,
              type = _ref.type,
              order_id = _ref.order_id;

          if (Ember.isPresent(token)) {
            if (type === 'authentication_token' && Ember.isPresent(order_id)) {
              headers['Authorization'] = "Token token=\"".concat(token, "\", order_id=\"").concat(order_id, "\"");
            } else if (type === 'token') {
              headers['Authorization'] = "Bearer ".concat(token);
            }
          }

          return headers;
        }
      }),
      init: function init() {
        var config = Ember.getOwner(this).resolveRegistration('config:environment');

        if (!config.urls.api) {
          (0, _logger.logger_error)("missing required url for api");
        }

        this.set('host', config.urls.api);

        this._super.apply(this, arguments);
      },
      buildModelUrl: function buildModelUrl(model, action) {
        var modelName = Ember.get(model, '_internalModel.modelName');
        var adapter = this.get('store').adapterFor(modelName);
        return adapter.buildURL(modelName, Ember.get(model, 'id')) + '/' + action;
      },
      processOptions: function processOptions(options) {
        if (Ember.isBlank(options)) {
          return {};
        }

        if (Ember.isPresent(options.data)) {
          options = Ember.assign({
            contentType: 'application/json; charset=UTF-8',
            dataType: 'json'
          }, options);
        }

        return options;
      },
      requestModel: function requestModel(model, action, options) {
        var url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.request(url, options);
      },
      postModel: function postModel(model, action, options) {
        var url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.post(url, options);
      },
      putModel: function putModel(model, action, options) {
        var url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.put(url, options);
      },
      patchModel: function patchModel(model, action, options) {
        var url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.patch(url, options);
      },
      delModel: function delModel(model, action, options) {
        var url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.del(url, options);
      },
      deleteModel: function deleteModel() {
        return this.delModel.apply(this, arguments);
      }
    });
  }

  var _default = {
    name: 'ember-ajax',
    initialize: initialize
  };
  _exports.default = _default;
});