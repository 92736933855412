define("shared/tests/helpers/create-customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(project, email) {
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    return server.create('customer', {
      email: email,
      token: token,
      project: project
    });
  }
});