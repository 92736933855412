define("shared/mixins/mobile-detection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // This can be overridden in the component that uses this
    // as a computed property in order to selectively turn off
    // the detection routines
    disable_mobile_detection: false,
    _did_event: false,
    desktop_min: 992,
    tablet_min: 768,
    is_mobile: false,
    is_tablet: false,
    resize: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('disable_mobile_detection')) {
        return;
      } // Run once and schedule the detection


      Ember.run.scheduleOnce('afterRender', this, this.checkMediaQuery);
      this.get('resize').on('resize', this, this.checkMediaQuery);
      this.set('_did_event', true);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('_did_event') !== true) {
        return;
      }

      this.get('resize').off('resize', this, this.checkMediaQuery);
    },
    checkMediaQuery: function checkMediaQuery() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      if (!window.matchMedia) {
        return;
      } // Matches variable in style for tablet


      if (window.matchMedia("(max-width: ".concat(this.get('desktop_min') - 1, "px) and (min-width: ").concat(this.get('tablet_min'), "px)")).matches) {
        this.set('is_tablet', true);
        return this.set('is_mobile', false);
      } else if (window.matchMedia("(max-width: ".concat(this.get('tablet_min') - 1, "px)")).matches) {
        this.set('is_tablet', false);
        return this.set('is_mobile', true);
      } else {
        this.set('is_tablet', false);
        return this.set('is_mobile', false);
      }
    }
  });

  _exports.default = _default;
});