define("shared/tests/helpers/create-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(project, name, price_cents, type) {
    var extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    var skip_variations = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var product = server.create('product', Ember.assign({
      product_type: type,
      project: project,
      name: name,
      price_cents: price_cents
    }, extra_data));

    if (['physical', 'digital', 'shipping'].includes(product.product_type) && skip_variations == false) {
      server.create('product-variation', {
        project: project,
        product: product,
        enabled: true,
        is_default: true,
        has_variants: false
      });
    }

    product.reload();
    return product;
  }
});