define("shared/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    alwaysIncludeLinkageData: true,
    serialize: function serialize(object
    /*, request*/
    ) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _emberCliMirage.JSONAPISerializer.prototype.serialize.apply(this, arguments);

      if ('models' in object) {
        json.meta = {
          'record-count': object.models.length
        };
      }

      return json;
    }
  });

  _exports.default = _default;
});