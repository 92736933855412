define("ember-cli-uncharted-describe-models/utils/load-models", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadModels = _exports.loadModel = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var dsTypes = {
    string: 'string',
    boolean: 'boolean',
    decimal: 'number',
    integer: 'number',
    date: 'date',
    datetime: 'date',
    object: 'object',
    jsonb: 'object',
    array: 'array',
    uuid: 'string',
    enum: 'string' // Not used at the moment
    // text:               'string',
    // 'null':             'string',
    // number:             'number',
    // money:              'number',
    // time:               'date',
    // json:               'object',
    // hstore:             'object',

  };

  var loadAttributres = function loadAttributres(properties, attributes) {
    // , _options
    for (var underscoredAttr in attributes) {
      if (!attributes.hasOwnProperty(underscoredAttr)) {
        continue;
      } // Perform shallow copy to avoid delete issues


      var attributeData = Object.assign({}, attributes[underscoredAttr]);
      var type = attributeData['type'];
      delete attributeData['type'];
      var attributeName = underscoredAttr;
      var attributeType = dsTypes[type] != null ? dsTypes[type] : type;

      if (dsTypes[type] == null) {
        window.console.warn("The type '".concat(type, "' was not found in dsTypes"));
      }

      if (!attributeName.match(/^id$/)) {
        if (attributeData != null && attributeData['defaultValue'] != null) {
          (function () {
            var defaultValue = attributeData['defaultValue'];

            if (_typeof(defaultValue) === 'object') {
              attributeData['defaultValue'] = function () {
                return defaultValue;
              };
            }
          })();
        }

        properties[attributeName] = _emberData.default.attr(attributeType, attributeData);
      }
    }
  };

  var cleanTableName = function cleanTableName(tableName) {
    tableName = tableName.replace('/', '-');
    tableName = tableName.replace(/_id/, '');
    tableName = Ember.String.dasherize(tableName);
    tableName = (0, _emberInflector.singularize)(tableName);
    return tableName;
  };

  var loadAssociations = function loadAssociations(properties, associations, options) {
    for (var assoc in associations) {
      if (!associations.hasOwnProperty(assoc)) {
        continue;
      }

      var info = associations[assoc];
      assoc = assoc.replace(/_id/, '');

      if (info == null) {
        continue;
      }

      var tableName = void 0;

      if ((tableName = info.has_many) || (tableName = info.has_and_belongs_to_many) || (tableName = info.embeds_many)) {
        var relationshipName = cleanTableName(tableName); // If the association is in the skip list, don't create it.

        if (options.skip.indexOf(relationshipName) >= 0) {
          continue;
        }

        var data = {
          async: info.async || true,
          polymorphic: info.polymorphic || false
        };

        if (info.hasOwnProperty('inverse')) {
          data.inverse = info.inverse;
        }

        properties[assoc] = _emberData.default.hasMany(relationshipName, data);
      } else if ((tableName = info.belongs_to) || (tableName = info.has_one) || (tableName = info.embedded_in) || (tableName = info.embeds_one)) {
        var _relationshipName = cleanTableName(tableName); // If the association is in the skip list, don't create it.


        if (options.skip.indexOf(_relationshipName) >= 0) {
          continue;
        }

        var _data = {
          async: info.async || true,
          polymorphic: info.polymorphic || false
        };

        if (info.hasOwnProperty('inverse')) {
          _data.inverse = info.inverse;
        }

        properties[assoc] = _emberData.default.belongsTo(_relationshipName, _data);
      } else {
        throw "Relation type binding missing.";
      }
    }
  };

  var loadModel = function loadModel(modelName, schema, options, config) {
    var properties = {}; // If the environment skip name is there, return

    if (options.skip.indexOf(modelName) >= 0) {
      return;
    }

    loadAttributres(properties, schema.attributes, options);
    loadAssociations(properties, schema.associations, options);
    config[modelName] = _emberData.default.Model.extend(properties);
  };

  _exports.loadModel = loadModel;

  var convertModelName = function convertModelName(modelName) {
    modelName = modelName.replace("::", "-");
    modelName = Ember.String.dasherize(modelName);
    return modelName;
  };

  var loadModels = function loadModels(modelNames, options) {
    if (Ember.isBlank(options)) {
      options = {};
    }

    if (Ember.isBlank(options.skip)) {
      options.skip = [];
    }

    var config = {};

    for (var modelName in modelNames) {
      var schema = modelNames[modelName];
      modelName = convertModelName(modelName);
      loadModel(modelName, schema, options, config);
    }

    return config;
  };

  _exports.loadModels = loadModels;
});