define("shared/mirage/helpers/crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COMMON_ENDPOINTS = void 0;
  _exports.getFilters = getFilters;
  _exports.getRequest = getRequest;
  var COMMON_ENDPOINTS = ['company', 'country', 'customer', 'newsletter', 'order-address', 'order-line', 'order-selection-question', 'order-selection', 'order-transaction', 'order', 'product-zone-location', 'product-zone', 'product-mapping', 'product-question-choice', 'product-question', 'product-stat', 'product-variant-value', 'product-variant', 'product-variation', 'product', 'project-address', 'project-configuration-stat', 'project-configuration', 'project-zone-location', 'project-zone', 'project-source', 'project-theme', 'project', 'state'];
  _exports.COMMON_ENDPOINTS = COMMON_ENDPOINTS;

  function getFilters(queryParams) {
    var filterRegEx = /filter\[(\w*)\]/;
    return Object.keys(queryParams).filter(function (k) {
      return k.match(filterRegEx);
    }).map(function (k) {
      return {
        key: k.match(filterRegEx)[1],
        value: queryParams[k]
      };
    });
  }

  function getRequest(plural_type, schema, request) {
    var filters = getFilters(request.queryParams);
    var modelName = Ember.String.camelize(plural_type);

    if (!filters.length) {
      return schema[Ember.String.camelize(plural_type)].all();
    }

    function customQueryFunction(obj) {
      var model = schema[modelName].find(obj.id);

      var _belongsTo = Object.keys(model.belongsToAssociations);

      var _hasMany = Object.keys(model.hasManyAssociations);

      return filters.every(function (filter) {
        var _key = filter.key;

        if (Ember.isEmpty(filter.value)) {
          return true;
        }

        if (_belongsTo.includes(_key)) {
          return model["".concat(Ember.String.camelize(_key), "Id")] == filter.value;
        }

        if (_hasMany.includes(_key)) {
          return model["".concat(Ember.String.camelize(_key), "Ids")].include(filter.value);
        }

        if (Ember.isArray(filter.value)) {
          return true;
        }

        if (['true', 'false'].includes(filter.value)) {
          var asBool = filter.value === 'true';
          return model[_key] === asBool;
        }

        if (_key === 'search') {
          // TODO
          return true;
        }

        if (filter.value.split(',').includes(String(model[_key]))) {
          return true;
        } // Don't use === here, it will cause issues


        return model[_key] == filter.value;
      });
    }

    return schema[Ember.String.camelize(plural_type)].where(customQueryFunction);
  }
});