define("shared/services/resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    _oldWidthDebounced: null,
    _oldHeightDebounced: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._onResizeHandler = function (evt) {
        Ember.run.debounce(_this, _this._fireDebouncedResizeNotification, evt, 500);
      };

      window.addEventListener('resize', this._onResizeHandler);
    },
    destory: function destory() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this._onResizeHandler);
    },
    _hasWindowSizeChanged: function _hasWindowSizeChanged(w, h) {
      return w !== this.get('_oldWidthDebounced') || h !== this.get('_oldHeightDebounced');
    },
    _fireDebouncedResizeNotification: function _fireDebouncedResizeNotification(evt) {
      var _window = window,
          innerWidth = _window.innerWidth,
          innerHeight = _window.innerHeight;

      if (this._hasWindowSizeChanged(innerWidth, innerHeight)) {
        this.trigger('resize', evt);
        this.setProperties({
          _oldWidthDebounced: innerWidth,
          _oldHeightDebounced: innerHeight
        });
      }
    }
  });

  _exports.default = _default;
});