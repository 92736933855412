define("shared/tests/helpers/create-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(project, customer, configuration, reward_selections, extra_selections, country) {
    var state = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : "UT";
    var step = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null;
    var approved_on = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : null;
    var order_data = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : {};
    var address = server.create('order-address', {
      name: "Aaron",
      address_1: "123 Test",
      city: "Draper",
      state_text: state,
      postal_code: "84020",
      project: project,
      country: country
    });
    var order = server.create('order', Ember.assign({
      origin: 'domestic',
      furthest_step: step,
      approved_on: approved_on,
      project: project,
      source: configuration.source,
      customer: customer,
      original_configuration: configuration,
      current_configuration: configuration,
      shipping_address: address
    }, order_data)); // server.db.orderAddresses.update(address.id, {
    //   order: order
    // });

    var reward_line = server.create('order-line', {
      line_type: 'reward',
      project: project,
      order: order,
      product_bundle: configuration.product_reward
    });

    for (var i = 0; i < reward_selections.length; i++) {
      var reward_product = reward_selections[i];
      var reward_variation = server.schema.productVariations.findBy({
        productId: reward_product.id,
        has_variants: false
      });
      server.create('order-selection', {
        quantity: 1,
        project: project,
        order: order,
        line: reward_line,
        product: reward_product,
        product_variation: reward_variation
      });
    }

    for (var j = 0; j < extra_selections.length; j++) {
      var addon_product = extra_selections[j];
      var addon_variation = server.schema.productVariations.findBy({
        productId: addon_product.id,
        has_variants: false
      });
      var line = server.create('order-line', {
        line_type: 'extra',
        project: project,
        order: order
      });
      server.create('order-selection', {
        quantity: 1,
        project: project,
        order: order,
        line: line,
        product: addon_product,
        product_variation: addon_variation
      });
    }

    server.create('order-transaction', {
      provider: 'Kickstarter',
      amount_cents: 1000,
      currency: 'USD',
      confirmed: true,
      project: project,
      order: order
    });
    return order;
  }
});