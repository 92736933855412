define("ember-cli-uncharted-describe-models/utils/model-definitions", ["exports", "ember-cli-uncharted-describe-models/utils/load-models"], function (_exports, _loadModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadModelDefinitions = _exports.loadDefinitions = _exports.default = void 0;
  var Definitions = {};

  var loadDefinitions = function loadDefinitions(definitions) {
    for (var key in definitions) {
      if (!definitions.hasOwnProperty(key)) {
        continue;
      }

      Definitions[key] = definitions[key];
    }
  };

  _exports.loadDefinitions = loadDefinitions;

  var loadModelDefinitions = function loadModelDefinitions(schema, options) {
    return function () {
      loadDefinitions((0, _loadModels.loadModels)(schema, options));
    };
  };

  _exports.loadModelDefinitions = loadModelDefinitions;
  var _default = Definitions;
  _exports.default = _default;
});