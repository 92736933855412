define("shared/mixins/zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Alias to have existing properties continue to work
    is_everywhere_else: Ember.computed.equal('location_type', 'everywhere_else'),
    is_countries: Ember.computed.equal('location_type', 'countries'),
    is_states: Ember.computed.equal('location_type', 'states'),
    // These names match the {location_type}_locations
    everywhere_else_locations: Ember.computed.filterBy('locations', 'is_everywhere_else', true),
    countries_locations: Ember.computed.filterBy('locations', 'is_countries', true),
    states_locations: Ember.computed.filterBy('locations', 'is_states', true),
    generated_name: Ember.computed('is_everywhere_else', 'is_countries', 'is_states', 'locations.@each.{country,state}', function () {
      if (this.get('is_everywhere_else')) {
        return "Everywhere Else";
      }

      if (this.get('locations') == null || this.get('locations.length') == 0) {
        // If we don't have any locations, then don't generate a name
        return null;
      }

      if (this.get('is_countries')) {
        return this.get('locations').mapBy('country.name').sort().join(', ');
      }

      if (this.get('is_states')) {
        var country_name = this.get('locations.firstObject.country.name');
        var state_names = this.get('locations').mapBy('state.name').sort().join(', ');
        return "".concat(country_name, " - States: ").concat(state_names);
      }

      return null;
    })
  });

  _exports.default = _default;
});