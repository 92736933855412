define("shared/initializers/bust-cache", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // Takes two parameters: container and app
  function initialize() {
    return (0, _jquery.default)(document).on('ajaxSend', function (event, xhr, settings) {
      if (settings.type === 'GET') {
        return settings.url += (settings.url.indexOf('?') > -1 ? "&" : "?") + "_=".concat(new Date().getTime());
      }
    });
  }

  var _default = {
    name: 'bust-cache',
    initialize: initialize
  };
  _exports.default = _default;
});