define("shared/components/product-configuration/component", ["exports", "shared/components/product-configuration/template", "shared/mixins/product-variation-selection"], function (_exports, _template, _productVariationSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_productVariationSelection.default, {
    classNames: ['item', 'product-configuration', 'component'],
    layout: _template.default,
    image_size: 200,
    mobile_image_size: 230,
    init: function init() {
      if (Ember.isBlank(this.get('order_selection'))) {
        throw "An order_selection is required";
      }

      if (Ember.isPresent(this.get('order_selection.product_option.id'))) {
        throw "Order Selection is not allowed be be used with a product option, is not supported in this component";
      } // Call init after we get order_selection


      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});