define("shared/mirage/factories/project", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    purpose: 'pledge_management',
    name: 'test',
    currency_symbol: '$',
    currency: 'USD',
    unit_of_mass: 'kg',
    shipping_type: 'product_price',
    address_name_type: 'single_name',
    slug: function slug(i) {
      return "1/".concat(i);
    }
  });

  _exports.default = _default;
});