define("shared/helpers/normalize-errors", ["exports", "shared/utils/has-own-property", "shared/helpers/object-if-present", "jquery", "@ember-data/adapter/error"], function (_exports, _hasOwnProperty, _objectIfPresent, _jquery, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.normalizeErrors = normalizeErrors;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var friendly = function friendly(text) {
    text = (text || "").trim();
    return text.replace(/_/g, ' ').replace(/\w\S*/g, function (text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    });
  };

  var cleanObject = function cleanObject(object) {
    for (var property in object) {
      if ((0, _hasOwnProperty.default)(object, property)) {
        if (Ember.isBlank(object[property])) {
          delete object[property];
        }
      }
    }

    return object;
  };

  var replaceIfDefaultAdapterError = function replaceIfDefaultAdapterError(error, defaultError, replacementMessage) {
    if (Ember.isPresent(error) && Ember.isPresent(error.errors)) {
      if (error.errors.length === 1) {
        var errorItem = error.errors[0];

        if (errorItem.title === defaultError.errors[0].title && errorItem.detail === defaultError.errors[0].detail) {
          delete error.errors;
          error.errors = [{
            title: replacementMessage,
            detail: replacementMessage
          }];
        }
      }
    }
  };

  var getErrorAttribute = function getErrorAttribute(errorItem) {
    var attribute = null; // if its blank or not an object, return, we can't get anything from that

    if (!Ember.isBlank(errorItem) && _typeof(errorItem) === "object") {
      // Attempt to get name from object now
      if (Ember.isPresent(errorItem.source) && Ember.isPresent(errorItem.source.pointer)) {
        var property_name = errorItem.source.pointer;
        attribute = property_name.substring(property_name.lastIndexOf('/') + 1);
      } else {
        attribute = (0, _objectIfPresent.objectIfPresent)(errorItem.name) || (0, _objectIfPresent.objectIfPresent)(errorItem.property) || (0, _objectIfPresent.objectIfPresent)(errorItem.attribute);
      }
    }

    return attribute;
  };

  var getErrorName = function getErrorName(errorItem) {
    var attribute = getErrorAttribute(errorItem);

    if (Ember.isPresent(attribute)) {
      return friendly(attribute);
    }

    return null;
  };

  var getErrorMessage = function getErrorMessage(errorItem) {
    if (typeof errorItem === "string") {
      return (errorItem || "").trim();
    } // if its blank or not an object, return, we can't get anything from that


    if (Ember.isBlank(errorItem) || _typeof(errorItem) !== "object") {
      return null;
    }

    var message = null; // Try to get from default invalid error first

    if (Ember.isPresent(errorItem.detail)) {
      if (Ember.isPresent(errorItem.detail.error)) {
        if (Ember.isPresent(errorItem.detail.error.message)) {
          message = errorItem.detail.error.message;
        } else {
          message = errorItem.detail.error;
        }
      } else {
        message = errorItem.detail;
      }
    }

    if (Ember.isPresent(errorItem.title)) {
      if (message == null) {
        message = errorItem.title;
      } else {
        // See if the detail message is essentially the attribute and the title combined.
        // If so, just return the title.
        if (message == "".concat(getErrorAttribute(errorItem), " - ").concat(errorItem.title)) {
          message = errorItem.title;
        }
      }
    }

    if (typeof message === "string" && Ember.isPresent(message)) {
      return (message || "").trim();
    }

    return "There was an unknown error.";
  };

  var getErrorMeta = function getErrorMeta(errorItem) {
    var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var meta = {};

    if (Ember.isPresent(error.stack)) {
      meta.stack = error.stack;
    }

    if (Ember.isPresent(error.lineNumber)) {
      meta.lineNumber = error.lineNumber;
    }

    if (Ember.isPresent(error.name)) {
      meta.name = error.name;
    }

    if (Ember.isPresent(error.message)) {
      meta.details = error.message;
    } else if (Ember.isPresent(errorItem.detail)) {
      meta.details = errorItem.detail;
    }

    if (Ember.isPresent(error.status)) {
      meta.status = error.status;
    } else if (Ember.isPresent(errorItem.status)) {
      meta.status = errorItem.status;
    }

    if (Ember.isPresent(meta) && !_jquery.default.isEmptyObject(meta)) {
      return cleanObject(meta);
    }

    return null;
  };

  var generateError = function generateError(errorItem, error) {
    var result = {
      name: getErrorName(errorItem),
      message: getErrorMessage(errorItem),
      meta: getErrorMeta(errorItem, error)
    };
    return cleanObject(result);
  };

  var generateErrors = function generateErrors(error) {
    var results = Ember.A();

    var _iterator = _createForOfIteratorHelper(error.errors),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var errorItem = _step.value;
        results.push(generateError(errorItem, error));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return results;
  };

  var processErrorsWithDefault = function processErrorsWithDefault(error, defaultMessage) {
    if (Ember.isPresent(error) && Ember.isPresent(error.errors)) {
      return generateErrors(error);
    }

    if (Ember.isPresent(error) && Ember.isArray(error)) {
      var results = Ember.A();

      var _iterator2 = _createForOfIteratorHelper(error),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var errorItem = _step2.value;
          results.push(generateError(errorItem));
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return results;
    }

    return [{
      message: defaultMessage
    }];
  }; // This functions tries to take various error states and return a normalized view reguardless of input.
  // Input could be string, adapter error, json error, devise error, jquery error
  // result always returns an array
  // Items in the array look like
  // {
  //    name (optional): a text friendly name for the error
  //    message: a friendly message for the error
  //    meta (optional): {
  //      lineNumber (optional): The line number it occured on
  //      name: (optional): Non friendly name
  //      details (optional): Non friendly stack trace
  //      stack (optional): a stack trace if available
  //      status: Any status codes present
  //    }
  // }


  function normalizeErrors(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        error = _ref2[0];

    if (Ember.isBlank(error) || _jquery.default.isPlainObject(error) && _jquery.default.isEmptyObject(error)) {
      return Ember.A();
    } // If the error is just a string


    if (typeof error === "string") {
      return [{
        message: error
      }];
    } // Specifically look for these three errors first.
    // In the future these errors will also exist.
    //  - UnauthorizedError, ForbiddenError, NotFoundError, ConflictError, ServerError
    // There isn't any contextual information on this error, generate info


    if (error instanceof _error.TimeoutError) {
      // Remove default error messages, they aren't added anyway
      replaceIfDefaultAdapterError(error, new _error.TimeoutError(), 'The adapter operation timed out');
      return processErrorsWithDefault(error);
    } // There isn't any contextual information on this error, generate info


    if (error instanceof _error.AbortError) {
      // Remove default error messages, they aren't added anyway
      replaceIfDefaultAdapterError(error, new _error.AbortError(), 'The adapter operation was aborted');
      return processErrorsWithDefault(error);
    } // We should have actual error information from this,


    if (error instanceof _error.InvalidError) {
      replaceIfDefaultAdapterError(error, new _error.InvalidError(), 'The adapter rejected the commit because it was invalid');
      return processErrorsWithDefault(error);
    } // AdapterError is the base of all ember data errors
    // If the error isn't a match above, and its an adapter error, default to that


    if (error instanceof _error.default) {
      replaceIfDefaultAdapterError(error, new _error.default(), 'Adapter operation failed');
      return processErrorsWithDefault(error);
    } // If we received an error, try to cast those into something


    return processErrorsWithDefault(error, 'There was an unknown error. Please review any changes and try again.');
  }

  var _default = Ember.Helper.helper(normalizeErrors);

  _exports.default = _default;
});