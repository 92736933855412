define("stripe/services/stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.stripeCallbacks = [];
    },
    initialized: Ember.computed.notEmpty('stripeElement'),
    load: function load(publishableKey) {
      var _this = this;

      var stripeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var onInit = function onInit() {
        _this.set('stripeElement', window.Stripe(publishableKey, stripeOptions)); // If there are any callbacks registered, call them now.


        _this.stripeCallbacks.forEach(function (callback) {
          return callback(_this.stripeElement);
        });
      };

      if (Ember.isEmpty(this.stripeElement)) {
        if (window.Stripe) {
          // Stripe has already loaded. Initialize now.
          onInit();
        } else {
          // Stripe hasn't loaded yet.
          // Watch for the load event on the script and initialize once loaded.
          var stripeJsElement = document.querySelector('#stripe-js');

          if (stripeJsElement) {
            stripeJsElement.addEventListener('load', function () {
              return onInit();
            });
          }
        }
      }
    },
    call: function call(callback) {
      if (this.stripeElement) {
        // If Stripe has already been initialized, call the callback immediately.
        callback(this.stripeElement);
      } else {
        // Stripe isn't ready; save for later.
        this.stripeCallbacks.push(callback);
      }
    }
  });

  _exports.default = _default;
});