define("shared/components/order-tax-totals/component", ["exports", "shared/components/order-tax-totals/template", "shared/utils/computed-coalesce"], function (_exports, _template, _computedCoalesce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: "",
    tax_expanded: false,
    can_expand: true,
    calculated_tax_costs: (0, _computedCoalesce.default)('calculated.total_tax_costs', 'order.total_tax_costs'),
    sales_tax_line: Ember.computed('order.tax_lines.@each.price_data_settings_symbol', function () {
      return this.get('order.tax_lines').findBy('price_data_settings_symbol', 'sales_tax');
    }),
    vat_lines: Ember.computed('order.tax_lines.@each.price_data_settings_symbol', function () {
      return this.get('order.tax_lines').filterBy('price_data_settings_symbol', 'vat');
    }),
    customs_line: Ember.computed('order.tax_lines.@each.price_data_settings_symbol', function () {
      return this.get('order.tax_lines').findBy('price_data_settings_symbol', 'customs');
    }),
    show: Ember.computed('calculated_tax_costs', 'sales_tax_line', 'vat_line', 'customs_line', function () {
      if (this.get('calculated_tax_costs') !== 0) {
        return true;
      }

      var items = [this.get('sales_tax_line'), this.get('vat_line'), this.get('customs_line')];

      for (var _i = 0, _items = items; _i < _items.length; _i++) {
        var item = _items[_i];

        if (Ember.isPresent(item) && item.get('prices.tax.amount') !== 0) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      toggle_tax: function toggle_tax() {
        if (this.get('can_expand') === false) {
          return;
        }

        this.toggleProperty('tax_expanded');
      }
    }
  });

  _exports.default = _default;
});