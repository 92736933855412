define("shared/components/order-review-selection/component", ["exports", "shared/components/order-review-selection/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['order-review-selection', 'component', 'row'],
    classNameBindings: ['order_selection.in_bundle:reward:extra', 'order_selection.is_question:questions'],
    order_selection: null,
    locked: Ember.computed('order_selection.locked.[]', 'order_selection.order.locked.[]', function () {
      if (this.get('order_selection.locked.length') > 0) {
        return this.get('order_selection.locked');
      }

      return this.get('order_selection.order.locked') || [];
    }),
    is_locked: Ember.computed.or('order_selection.is_locked', 'order_selection.order.is_locked'),
    iconForLockType: Ember.computed('locked', function () {
      var process = this.get('locked').map(function (item) {
        return item.get('process');
      }).join(',');

      switch (process) {
        case 'export':
          return 'file alternate';

        case 'ship':
          return 'box';

        case 'digital':
          return 'headphones';

        default:
          // All others (including multiple exports)
          return 'lock';
      }
    })
  });

  _exports.default = _default;
});